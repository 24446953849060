import { AuthUserInfo } from './interface/auth.interface';

export const isBrowser = () => typeof window !== 'undefined';

// TODO: below is for testing switching to redirect for all cases ( instead of FB in-app browser only )
export const shouldUseRedirectLogin = () => {
  return true;
};

export const isFacebookInAppBrowser = () => {
  if (isBrowser()) {
    const ua = navigator?.userAgent;

    if ((ua?.indexOf('FBAN') > -1 || ua?.indexOf('FB_IAB') > -1) && ua?.indexOf('FBAV') > -1) {
      return true;
    }
  }
  return false;
};

export const getSignedUpDate = (userInfo: AuthUserInfo) => {
  return userInfo?.createdAt;
};

export const openPopUpForAuth = (
  features = 'left=100,top=100,width=400,height=600,resizable,scrollbars=yes,status=1'
) => {
  const popup = window.open('', 'auth0:authorize:popup', features);
  if (!popup) {
    throw new Error('Could not open popup');
  }

  return popup;
};

export const checkIsLocalHost = () => {
  return window.location.origin.includes('localhost');
  // return new Promise(function (resolve) {
  //   const frame = document.createElement('iframe');
  //   frame.id = '3pc';
  //   frame.src = 'https://akropolisio.github.io/3rd-party-cookies-checker/read-cookie.html';
  //   frame.style.display = 'none';
  //   frame.style.position = 'fixed';
  //
  //   setTimeout(() => {
  //     resolve(true);
  //   }, 3000);
  //
  //   const messageHandler = (event) => {
  //     // check for trusted origins here
  //     if (event.data && typeof event.data === 'string') {
  //       console.log('checkThirdPartyCookieEnabled', event);
  //       const isSupported = event.data.includes('unsupported') === false;
  //       window.removeEventListener('message', messageHandler);
  //       document.body.removeChild(frame);
  //       resolve(isSupported);
  //     }
  //   };
  //   window.addEventListener('message', messageHandler);
  //
  //   document.body.appendChild(frame);
  // });
};

export const isInAppBrowser = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const { userAgent } = window.navigator;

  if (userAgent.includes('FBAN') || userAgent.includes('FBAV') || userAgent.includes('FB_IAB')) {
    return true; // Facebook app
  }
  if (userAgent.includes('Twitter')) {
    return true; // Twitter app
  }
  if (userAgent.includes('GSA/')) {
    return true; // Google Search App
  }
  if (userAgent.includes('Instagram')) {
    return true; // Instagram app
  }
  if (userAgent.includes('Pinterest')) {
    return true; // Pinterest app
  }

  return false;
};
