/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Database,
  DataSnapshot,
  get,
  getDatabase,
  onValue,
  ref,
  remove,
  set,
  Unsubscribe,
  update,
} from '@firebase/database';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { noop } from 'lodash';

let firebaseDBManager: FireBaseRealTimeDBManager;

export class FireBaseRealTimeDBManager {
  readonly db: Database;

  haveLoggedIn: boolean;

  readonly app: FirebaseApp;

  auth: any;

  constructor(config) {
    this.app = config?.app ? config?.app : initializeApp(config);
    this.db = getDatabase(this.app);
    this.auth = getAuth(this.app);
    this.haveLoggedIn = false;
  }

  writeData(refPath, value, disabled = false) {
    if (disabled || !this.isLoggedIn()) return;
    return set(ref(this.db, refPath), value);
  }

  readData(refPath, disabled = false) {
    if (disabled || !this.isLoggedIn()) return;
    return get(ref(this.db, refPath));
  }

  removeValue(refPath, disabled = false) {
    if (disabled || !this.isLoggedIn()) return;
    return remove(ref(this.db, refPath));
  }

  resetData(refPath) {
    if (!this.isLoggedIn()) return null;
    return this.writeData(refPath, null);
  }

  listenForValueEvents({
    refPath,
    callBack,
    cancelCallback = noop,
    disabled = false,
  }: {
    refPath;
    callBack: (data: DataSnapshot) => unknown;
    cancelCallback?: (error: Error) => unknown;
    disabled?: boolean;
  }): Unsubscribe {
    if (disabled || !this.isLoggedIn()) return noop;
    const refValue = ref(this.db, refPath);
    return onValue(refValue, callBack, cancelCallback);
  }

  updateData(updatePayload, disabled = false) {
    if (disabled || !this.isLoggedIn()) return;
    return update(ref(this.db), updatePayload);
  }

  isLoggedIn() {
    if (this.haveLoggedIn) {
      return true;
    }
    return !!this.auth.currentUser;
  }

  async forceRealtimeLogin() {
    this.haveLoggedIn = true;
  }

  async loginByToken(token) {
    if (this.auth.currentUser) {
      return;
    }
    try {
      const userCredentials = await signInWithCustomToken(this.auth, token);
      window.localStorage.setItem('firebase.db.token', token);
      this.haveLoggedIn = true;
      return userCredentials;
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('login by token error', errorCode, errorMessage);
    }
  }
}

class FireBaseRealTimeDBManagerFake {
  constructor(config: any) {}

  writeData(refPath, value, disabled = false) {
    return;
  }

  readData(refPath, disabled = false) {
    return;
  }

  removeValue(refPath, disabled = false) {
    return;
  }

  resetData(refPath) {
    return null;
  }

  listenForValueEvents({
    refPath,
    callBack,
    cancelCallback = noop,
    disabled = false,
  }: {
    refPath;
    callBack: (data: DataSnapshot) => unknown;
    cancelCallback?: (error: Error) => unknown;
    disabled?: boolean;
  }): Unsubscribe {
    return noop;
  }

  updateData(updatePayload, disabled = false) {}

  isLoggedIn() {
    return false;
  }

  async loginByToken(token) {}
}

let firebaseDBFake = null;

export const bootstrapFirebaseRealTimeDB = (config) => {
  firebaseDBManager = new FireBaseRealTimeDBManager(config);
  return firebaseDBManager;
};

export const useFireBaseDB = () => {
  if (!firebaseDBManager) {
    if (!firebaseDBFake) {
      firebaseDBFake = new FireBaseRealTimeDBManagerFake({});
    }
    return firebaseDBFake;
  }
  return firebaseDBManager;
};
