import dynamic from 'next/dynamic';
import * as React from 'react';

import { PageLoader } from '../PageLoader/PageLoader';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';

const UserBannedModal = dynamic<any>(
  () => import('./UserBannedModal').then((mod) => mod.UserBannedModal),
  { loading: () => <PageLoader />, ssr: false }
);

export const withUserBanned = (WrappedComponent: any) => (props: any) => {
  const [isBannedModalOpen, setBannedModalOpen] = React.useState(false);
  const { isLoggedIn, userInfo } = useGetAuthInfo();

  React.useEffect(() => {
    if (isLoggedIn && !isBannedModalOpen) {
      const isBanned = userInfo?.userMetadata?.banned;
      if (isBanned) {
        setBannedModalOpen(true);
      }
    }
  }, [isLoggedIn, isBannedModalOpen, userInfo]);

  return (
    <>
      <WrappedComponent {...props} />
      {isBannedModalOpen && (
        <UserBannedModal
          isBannedModalOpen={isBannedModalOpen}
          onCloseBanner={() => setBannedModalOpen(false)}
        />
      )}
    </>
  );
};
