import * as React from 'react';
import { useRouter } from 'next/router';
import { useAppScrollRef } from '@doltech/core/lib/appScroll/withAppScroll';
import { setScrollYOf } from '@doltech/core/lib/hooks/useScrollElement';
import { takeRight } from 'lodash';
import Head from 'next/head';

export interface ClientRenderProps {}

export const useTrackingLastUrlAndAutoScrollToTop = () => {
  const router = useRouter();
  const { appScrollRef } = useAppScrollRef();
  React.useEffect(() => {
    const routerChangeCompleteHandler = (newUrl) => {
      let lastUrlValue = [];
      try {
        lastUrlValue = JSON.parse(sessionStorage.getItem('lastUrl')) || [];
        // eslint-disable-next-line no-empty
      } catch (ex) {}
      lastUrlValue.push(newUrl);
      sessionStorage.setItem('lastUrl', JSON.stringify(takeRight(lastUrlValue, 2)));
      if (!router.asPath.includes('#')) setScrollYOf(appScrollRef.current, 0);
    };

    router.events.on('routeChangeComplete', routerChangeCompleteHandler);
    return () => {
      router.events.off('routeChangeComplete', routerChangeCompleteHandler);
    };
  }, [appScrollRef, router.asPath, router.events]);
};

export const withDolSetup = (options) => (Component) => (props) => {
  return (
    <div id="app-root" className="app-wrapper">
      <Head>
        {options.renderMeta ? (
          options.renderMeta()
        ) : (
          <title>tuhocielts.dolenglish.vn - Học Tiếng Anh Free - Chất lượng Premium</title>
        )}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1"
        />
      </Head>
      <Component {...props} />
    </div>
  );
};
